import { Input } from "@chakra-ui/react";
import React from "react";

export const AppInput = ({ ...rest }) => {
	return (
		<Input
			fontSize="sm"
			borderRadius="none"
			borderColor="gray.100"
			{...rest}
		/>
	);
};
