import {
	Box,
	Flex,
	Grid,
	Heading,
	List,
	ListItem,
	Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import {
	AvatarIcon,
	ClockIcon,
	ContactIcon,
	EmptyBoxIcon,
	homebg,
	LocationIcon,
	LogoIcon,
	MarionIcon,
	RoundLocationIcon,
	StatusIcon,
} from "../../assets";
import { AppInput, Button } from "../../components";
import { BASE_URL } from "../../constants";
import { MainLayout } from "../../layouts";

export const Tracking = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (searchTerm) {
			setLoading(true);
			axios(`${BASE_URL}/search_or?TrackingId=${searchTerm}`)
				.then((data) => setData(data.data?.[0]))
				.catch((error) => console.log(error))
				.finally(() => {
					setLoading(false);
					setSearchTerm("");
				});
		}
	};

	console.log(data);

	return (
		<MainLayout>
			<Box>
				<Box
					as="section"
					backgroundColor="orange.100"
					backgroundImage={homebg}
					backgroundRepeat="no-repeat"
					backgroundPosition={["right", "center"]}
					backgroundSize="cover"
					position="relative"
					color="white"
					py="70"
					mb="20"
				>
					<Box
						position="absolute"
						inset="0"
						background="rgba(255,107,33,0.5)"
					></Box>
					<Box className="container" position="relative">
						<Heading>
							<Flex
								justifyContent="center"
								sx={{
									"& svg": {
										width: "40px",
										height: "40px",
										verticalAlign: "middle",
										fill: "white",
									},
								}}
							>
								<LocationIcon />
								Tracking
							</Flex>
						</Heading>
					</Box>
				</Box>
				<Box className="container">
					<Box>
						<Box as="form" onSubmit={handleSubmit}>
							<Flex>
								<AppInput
									placeholder="Input Track Code"
									value={searchTerm}
									onChange={handleSearch}
									required={true}
								/>
								<Button type="submit" disabled={loading}>
									Search
								</Button>
							</Flex>
						</Box>
						<Box as="section" my={["10", "20"]}>
							{loading ? (
								<Box textAlign={"center"} py="14">
									<div className="lds-roller">
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
									</div>
								</Box>
							) : (
								<>
									{" "}
									{!data ? (
										<Flex
											flexDirection="column"
											alignItems="center"
											sx={{
												"& svg": {
													width: "200px",
													height: "200px",
													verticalAlign: "middle",
													fill: "orange.100",
												},
											}}
										>
											<EmptyBoxIcon />
											<Text fontWeight={900}>
												No data to display!
											</Text>
										</Flex>
									) : (
										<Box
											border="3px solid"
											borderColor="orange.100"
											p="6"
											maxW={700}
											margin="0 auto"
										>
											<Flex
												alignItems="center"
												justifyContent="center"
												sx={{
													"& svg": {
														width: "50px",
														height: "50px",
													},
												}}
											>
												<LogoIcon />
												<Text
													as="span"
													color="blue.100"
													fontSize="lg"
													fontWeight="900"
													ml="2"
												>
													KTparcels
												</Text>
											</Flex>
											<Text
												fontSize="small"
												fontWeight={600}
												textAlign="center"
												mb="6"
											>
												View details of your Consignment
												below.
											</Text>
											<Grid
												templateColumns={{
													md: "repeat(2, 1fr)",
												}}
												gap={{ base: 5, md: 20 }}
												sx={{
													"& svg": {
														width: "20px",
														height: "20px",
														verticalAlign: "middle",
														marginRight: "4px",
													},
												}}
											>
												<Box>
													<Heading
														as="h4"
														size={"sm"}
														mb="3"
														fontWeight={700}
													>
														PARCEL DETAILS
													</Heading>
													<List
														my="3"
														sx={{
															"& li": {
																marginBottom:
																	"14px",
															},
														}}
													>
														<ListItem>
															<Grid templateColumns="24px 1fr">
																<RoundLocationIcon />
																<Text
																	fontSize="xs"
																	fontWeight={
																		900
																	}
																>
																	Tracking ID:{" "}
																	{
																		data?.TrackingId
																	}
																</Text>
															</Grid>
														</ListItem>
														<ListItem>
															<Grid templateColumns="24px 1fr">
																<MarionIcon />
																<Text
																	fontSize="xs"
																	fontWeight={
																		900
																	}
																>
																	Description:{" "}
																	{
																		data?.Description
																	}
																</Text>
															</Grid>
														</ListItem>
														<ListItem>
															<Grid templateColumns="24px 1fr">
																<EmptyBoxIcon />
																<Text
																	fontSize="xs"
																	fontWeight={
																		900
																	}
																>
																	Parcel
																	Weight:{" "}
																	{
																		data?.Weight
																	}
																</Text>
															</Grid>
														</ListItem>
														<ListItem>
															<Grid templateColumns="24px 1fr">
																<AvatarIcon />
																<Text
																	fontSize="xs"
																	fontWeight={
																		900
																	}
																>
																	Receiver
																	Name:{" "}
																	{
																		data?.ReceiverName
																	}
																</Text>
															</Grid>
														</ListItem>
														<ListItem>
															<Grid templateColumns="24px 1fr">
																<ContactIcon />
																<Text
																	fontSize="xs"
																	fontWeight={
																		900
																	}
																>
																	Receiver
																	Address:{" "}
																	{
																		data?.ReceiverAddress
																	}
																</Text>
															</Grid>
														</ListItem>
														<ListItem>
															<Grid templateColumns="24px 1fr">
																<StatusIcon />
																<Text
																	fontSize="xs"
																	fontWeight={
																		900
																	}
																>
																	Delivery
																	Status:
																	{
																		data?.DeliveryStatus
																	}
																</Text>
															</Grid>
														</ListItem>
													</List>
												</Box>
												<Box>
													<Heading
														as="h4"
														size={"sm"}
														mb="3"
														fontWeight={700}
													>
														CURRENT LOCATION
													</Heading>
													<List
														my="3"
														sx={{
															"& li": {
																marginBottom:
																	"14px",
															},
														}}
													>
														<ListItem>
															<Grid templateColumns="24px 1fr">
																<ClockIcon />
																<Text
																	fontSize="xs"
																	fontWeight={
																		900
																	}
																>
																	Date & Time:{" "}
																	{
																		data?.DateAndTime
																	}
																</Text>
															</Grid>
														</ListItem>
														<ListItem>
															<Grid templateColumns="24px 1fr">
																<StatusIcon />
																<Text
																	fontSize="xs"
																	fontWeight={
																		900
																	}
																>
																	Status:{" "}
																	{
																		data?.Status
																	}
																</Text>
															</Grid>
														</ListItem>
														<ListItem>
															<Grid templateColumns="24px 1fr">
																<RoundLocationIcon />
																<Text
																	fontSize="xs"
																	fontWeight={
																		900
																	}
																>
																	Location:{" "}
																	{
																		data?.Location
																	}
																</Text>
															</Grid>
														</ListItem>
													</List>
												</Box>
											</Grid>
										</Box>
									)}
								</>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</MainLayout>
	);
};
