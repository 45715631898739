import React from "react";
import {
	Box,
	Grid,
	GridItem,
	Heading,
	Link,
	List,
	ListItem,
	Stack,
	Text,
} from "@chakra-ui/react";
import {
	FacebookIcon,
	InstagramIcon,
	LogoIcon,
	TwitterIcon,
} from "../../assets";
import { Button } from "../button";
import { AppInput } from "../input";
import { NavLink as ReactLink } from "react-router-dom";

export const Footer = () => {
	return (
		<Box as="footer" fontSize="sm" fontWeight="500" color="gray.200">
			<Box background="gray.50" py="10">
				<Box className="container">
					<Stack
						direction={{ base: "column", lg: "row" }}
						alignItems={{ lg: "center" }}
						gap={{ base: 5 }}
					>
						<Box>
							<Heading as="h4" size="md" mb="2" color="blue.100">
								Our Newsletter
							</Heading>
							<Text>
								Sign up for industry alerts, news &amp;
								insights.
							</Text>
						</Box>
						<Stack
							direction={{ base: "column", lg: "row" }}
							spacing={{ base: 5, lg: 0 }}
							as="form"
						>
							<AppInput required placeholder="Your Name" />
							<AppInput
								required
								type="email"
								placeholder="Your Email"
							/>
							<Button marginLeft="200px">Submit Now!</Button>
						</Stack>
						<Stack
							sx={{
								"& svg": { height: "14px", width: "14px" },
								"& a:hover": { borderColor: "orange.100" },
							}}
							direction={{ base: "row" }}
							justifyContent={{ base: "right" }}
						>
							<Link
								href="https://facebook.com"
								isExternal
								border="1px solid"
								padding="2"
							>
								<FacebookIcon />
							</Link>
							<Link
								href="https://twitter.com"
								isExternal
								border="1px solid"
								padding="2"
							>
								<TwitterIcon />
							</Link>
							<Link
								href="https://instagram.com"
								isExternal
								border="1px solid"
								padding="2"
							>
								<InstagramIcon />
							</Link>
						</Stack>
					</Stack>
				</Box>
			</Box>
			<Box py={["30", "50"]}>
				<Grid
					className="container"
					templateColumns={{ xl: "repeat(3, 1fr)" }}
					gap={{ base: 12, lg: 20 }}
					alignItems={{ xl: "center" }}
				>
					<GridItem>
						<Box
							sx={{
								"& svg": {
									width: "50px",
									height: "50px",
								},
							}}
						>
							<LogoIcon />
							<Text my="3">
								Av. Krieg, 1208 Genève, Switzerland
							</Text>
							<Text>
								Email:{" "}
								<Text as="span" color="blue.100">
									ktparcels@gmail.com
								</Text>
							</Text>
							<Text>
								Phone:{" "}
								<Text as="span" color="blue.100">
									+4617847935
								</Text>
							</Text>
						</Box>
					</GridItem>
					<GridItem colSpan={2}>
						<Stack
							direction={{ base: "column", md: "row" }}
							spacing={{ base: 6, md: 12, lg: 24 }}
						>
							<Box>
								<Text mb="5" color="blue.100" fontWeight="700">
									Explore
								</Text>
								<List
									sx={{
										"& li": {
											marginBottom: "10px",
										},
									}}
								>
									<ListItem>
										<Link as={ReactLink} to="/">
											Home
										</Link>
									</ListItem>
									<ListItem>
										<Link as={ReactLink} to="/tracking">
											Track
										</Link>
									</ListItem>
									<ListItem>
										<Link as={ReactLink} to="/about">
											About
										</Link>
									</ListItem>
									<ListItem>
										<Link as={ReactLink} to="/contact">
											Contact
										</Link>
									</ListItem>
								</List>
							</Box>
							<Box>
								<Text mb="5" color="blue.100" fontWeight="700">
									Support
								</Text>
								<List
									sx={{
										"& li": {
											marginBottom: "10px",
										},
									}}
								>
									<ListItem>
										<Link as={ReactLink} to="/contact">
											Shipping & Returns
										</Link>
									</ListItem>
									<ListItem>
										<Link as={ReactLink} to="/contact">
											Store Policy
										</Link>
									</ListItem>
									<ListItem>
										<Link as={ReactLink} to="/contact">
											Payment Methods
										</Link>
									</ListItem>
								</List>
							</Box>
							<Box>
								<Text mb="5" color="blue.100" fontWeight="700">
									Quick Links
								</Text>
								<List
									sx={{
										"& li": {
											marginBottom: "10px",
										},
									}}
								>
									<ListItem>
										<Link as={ReactLink} to="/">
											Request A Quote
										</Link>
									</ListItem>
									<ListItem>
										<Link as={ReactLink} to="/tracking">
											Track &amp; Trace
										</Link>
									</ListItem>
									<ListItem>
										<Link as={ReactLink} to="/">
											Find A Location
										</Link>
									</ListItem>
									<ListItem>
										<Link as={ReactLink} to="/">
											Global Agents
										</Link>
									</ListItem>
								</List>
							</Box>
						</Stack>
					</GridItem>
				</Grid>
			</Box>
			<Box borderTop="1px solid" borderColor="gray.100">
				<Box className="container" fontSize="x-small" py="5">
					<Text textAlign="center">
						&copy; 2022{" "}
						<Text as="span" color="orange.100" fontWeight="900">
							KTparcels
						</Text>{" "}
						- All rights reserved.
					</Text>
				</Box>
			</Box>
		</Box>
	);
};
