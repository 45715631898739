import { Box, Flex, Grid, Heading, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import {
	BoatsIcon,
	CactusIcon,
	cargoPlane,
	cargoShip,
	cargoTruck,
	CartIcon,
	homebg,
	MarionIcon,
	ProxibidIcon,
	SearchIcon,
	sectionThreeImage,
	sectionTwoImage,
	ShieldIcon,
	ThamesIcon,
	WalletIcon,
	XcapitalIcon,
} from "../../assets";
import { Button } from "../../components";
import { MainLayout } from "../../layouts";
import { NavLink as ReactLink } from "react-router-dom";

export const Home = () => {
	return (
		<MainLayout>
			<Box
				as="section"
				backgroundColor="orange.100"
				backgroundImage={homebg}
				backgroundRepeat="no-repeat"
				backgroundPosition={["right", "center"]}
				backgroundSize="cover"
				position="relative"
				color="white"
			>
				<Box
					position="absolute"
					inset="0"
					background="rgba(255,107,33,0.5)"
				></Box>
				<Box
					className="container"
					py={{ base: 20, md: 40 }}
					pb={{ base: 20, md: 40, lg: 5 }}
					position="relative"
				>
					<Heading
						as="h1"
						size={{ base: "3xl", md: "4xl" }}
						width={{ md: "80%" }}
					>
						Affordable Price, Certified Experts &amp; Innovative
						Solutions.
					</Heading>
					<Text my="6" width={{ md: "80%" }}>
						Our cargos are highly affordable and shipping are
						entirely secured across all freight system until parcel
						destination is confirmed.
					</Text>
					<Button variant="outline">
						<Link as={ReactLink} to="/about">
							About Us
						</Link>
					</Button>
				</Box>
				<Box
					className="container"
					bg="white"
					position="relative"
					bottom={{ lg: "-100" }}
					zIndex="10"
					px={0}
					py={{ base: 10, lg: 0 }}
				>
					<Grid
						templateColumns={{
							md: "repeat(2, 1fr)",
							lg: "repeat(4, 1fr)",
						}}
						gap={{ base: 0 }}
						sx={{
							"& svg": {
								width: "40px",
								height: "40px",
							},
						}}
					>
						<Box
							p={{ base: 5, md: 10 }}
							borderRight="1px solid"
							borderBottom={{ base: "1px solid", lg: "none" }}
							borderColor="gray.100"
						>
							<WalletIcon />
							<Heading as="h5" color="blue.100" size="md" my="3">
								Transparent Pricing
							</Heading>
							<Text color="gray.200" size="sm">
								KTparcels has the best pricing for your shipping
								needs.
							</Text>
						</Box>
						<Box
							p={{ base: 5, md: 10 }}
							borderRight="1px solid"
							borderBottom={{ base: "1px solid", lg: "none" }}
							borderColor="gray.100"
						>
							<SearchIcon />
							<Heading as="h5" color="blue.100" size="md" my="3">
								Real-Time Tracking
							</Heading>
							<Text color="gray.200" size="sm">
								KTparcels supports real-time cargo tracking, so
								you can monitor your item on the go.
							</Text>
						</Box>
						<Box
							p={{ base: 5, md: 10 }}
							borderRight="1px solid"
							borderBottom={{ base: "1px solid", lg: "none" }}
							borderColor="gray.100"
						>
							<CartIcon />
							<Heading as="h5" color="blue.100" size="md" my="3">
								Warehouse Storage
							</Heading>
							<Text color="gray.200" size="sm">
								Robust and safe warehouse storage than meets
								everyones needs.
							</Text>
						</Box>
						<Box
							p={{ base: 5, md: 10 }}
							borderBottom={{ base: "1px solid", lg: "none" }}
						>
							<ShieldIcon />
							<Heading as="h5" color="blue.100" size="md" my="3">
								Security For Cargo
							</Heading>
							<Text color="gray.200" size="sm">
								KTparcels provides added security for your
								cargo. 100% tested and trusted.
							</Text>
						</Box>
					</Grid>
				</Box>
			</Box>
			<Box
				as="section"
				bg="gray.50"
				pb={{ base: 20 }}
				pt={{ base: 20, lg: 180 }}
			>
				<Box className="container" px={{ base: "4%", xl: 0 }}>
					<Flex
						direction={{ base: "column", lg: "row" }}
						alignItems={{ md: "center" }}
						gap={{ base: 5, md: 7, lg: 10, xl: 20 }}
					>
						<Box>
							<Box
								width={{
									base: "100%",
									md: "380px",
									lg: "500px",
								}}
								height={{
									base: "300",
									md: "380px",
									lg: "500px",
								}}
								bg="orange.100"
							>
								<Image
									src={sectionTwoImage}
									objectFit="cover"
									height="100%"
									width="100%"
								/>
							</Box>
						</Box>
						<Box>
							<Heading
								as="h6"
								fontSize="sm"
								fontWeight="900"
								color="orange.100"
							>
								THE MISSION
							</Heading>
							<Heading
								as="h4"
								fontSize={{ base: "3xl", md: "4xl" }}
								fontWeight="800"
								color="blue.100"
								lineHeight={{ base: "2.5rem", md: "3rem" }}
								my="4"
							>
								Be a global player in the courier industry, with
								excellence in products and services.
							</Heading>
							<Text
								color="gray.200"
								size="sm"
								lineHeight="1.8rem"
							>
								KTparcels' ability is to identify and understand
								its clients needs, fulfill and maintain service
								levels and continuously adapt and develop
								systems to fulfill all requirements, To plan,
								develop and continuously improve our facilities
								and services. To be customer driven in
								everything we do. To provide an ideal working
								environment that boosts co-operation and
								teamwork.
							</Text>
						</Box>
					</Flex>
				</Box>
			</Box>
			<Box as="section" bg="orange.100" py={{ base: 20 }}>
				<Box className="container" px={{ base: "4%", xl: 0 }}>
					<Flex
						direction={{ base: "column", lg: "row" }}
						alignItems={{ md: "center" }}
						gap={{ base: 5, md: 7, lg: 10, xl: 20 }}
					>
						<Box order={{ base: 0, lg: 2 }}>
							<Box
								width={{
									base: "100%",
									md: "380px",
									lg: "500px",
								}}
								height={{
									base: "300",
									md: "380px",
									lg: "500px",
								}}
								bg="orange.100"
							>
								<Image
									src={sectionThreeImage}
									objectFit="cover"
									height="100%"
									width="100%"
								/>
							</Box>
						</Box>
						<Box>
							<Heading
								as="h6"
								fontSize="sm"
								fontWeight="900"
								color="white"
							>
								OUR STRATEGY
							</Heading>
							<Heading
								as="h4"
								fontSize={{ base: "3xl", md: "4xl" }}
								fontWeight="800"
								color="blue.100"
								lineHeight={{ base: "2.5rem", md: "3rem" }}
								my="4"
							>
								KTparcels will work closely with you to devise
								customized solutions and route optimization that
								will help your business improve customer service
								and decrease operational costs
							</Heading>
							<Text color="white" size="sm" lineHeight="1.8rem">
								KTparcels works with clients to develop and
								execute communication processes that optimize
								the flow of products and information within the
								supply chain and within each distribution
								center.
							</Text>
						</Box>
					</Flex>
				</Box>
			</Box>
			<Box as="section" bg="gray.50" py={{ base: 20 }}>
				<Box className="container" px={{ base: "4%", xl: 0 }}>
					<Box textAlign="center" mb="10">
						<Heading
							as="h6"
							fontSize="sm"
							fontWeight="900"
							color="orange.100"
						>
							OUR SERVICES
						</Heading>
						<Text
							color="gray.200"
							size="sm"
							lineHeight="1.8rem"
							width={{ md: "60%", lg: "50%" }}
							margin="0 auto"
							my="4"
						>
							WE HAVE WIDE NETWORK OF OFFICES IN ALL MAJOR
							LOCATIONS TO HELP YOU WITH THE SERVICES WE OFFER
						</Text>
					</Box>
					<Grid
						templateColumns={{ md: "repeat(3, 1fr)" }}
						gap={{ base: 10, md: 5 }}
					>
						<Box>
							<Box
								width={{
									base: "100%",
									md: "200px",
									lg: "300px",
								}}
								height={{
									base: "300",
									md: "200px",
									lg: "300px",
								}}
								bg="orange.100"
							>
								<Image
									src={cargoPlane}
									objectFit="cover"
									height="100%"
									width="100%"
								/>
							</Box>
							<Box>
								<Heading
									as="h5"
									fontSize={{ base: "sm", md: "md" }}
									fontWeight="800"
									color="blue.100"
									my="4"
								>
									AIR FREIGHT
								</Heading>
								<Text
									color="gray.200"
									size="sm"
									lineHeight="1.8rem"
									width={{ md: "90%" }}
								>
									KTparcels uses major international air
									liners to deliver cargo to all cities,
									countries and continents. Our mutual
									partnerships with major air carriers give
									our customer cargoes priority space
									allocation at very competitive rates...
								</Text>
							</Box>
						</Box>
						<Box>
							<Box
								width={{
									base: "100%",
									md: "200px",
									lg: "300px",
								}}
								height={{
									base: "300",
									md: "200px",
									lg: "300px",
								}}
								bg="orange.100"
							>
								<Image
									src={cargoShip}
									objectFit="cover"
									height="100%"
									width="100%"
								/>
							</Box>
							<Box>
								<Heading
									as="h5"
									fontSize={{ base: "sm", md: "md" }}
									fontWeight="800"
									color="blue.100"
									my="4"
								>
									SEA FREIGHT
								</Heading>
								<Text
									color="gray.200"
									size="sm"
									lineHeight="1.8rem"
									width={{ md: "90%" }}
								>
									KTparcels offers its Sea Freight Services at
									competitive advantage, benchmarking on her
									international partners contract with many of
									the world’s leading ocean carriers. .We
									handle all sea freight businesses and all
									other international security standards and
									environmental standards...
								</Text>
							</Box>
						</Box>
						<Box>
							<Box
								width={{
									base: "100%",
									md: "200px",
									lg: "300px",
								}}
								height={{
									base: "300",
									md: "200px",
									lg: "300px",
								}}
								bg="orange.100"
							>
								<Image
									src={cargoTruck}
									objectFit="cover"
									height="100%"
									width="100%"
								/>
							</Box>
							<Box>
								<Heading
									as="h5"
									fontSize={{ base: "sm", md: "md" }}
									fontWeight="800"
									color="blue.100"
									my="4"
								>
									LAND LOGISTICS
								</Heading>
								<Text
									color="gray.200"
									size="sm"
									lineHeight="1.8rem"
									width={{ md: "90%" }}
								>
									KTparcels offers an efficient integrated
									transport system with multiple types of
									trucks available to support your trucking
									and distribution needs. All cargos are
									handled carefully, tracked and distributed
									in precise accordance with our customer’s
									schedule, assuring on-time deliveries...
								</Text>
							</Box>
						</Box>
					</Grid>
				</Box>
			</Box>
			<Box as="section" bg="white" py={{ base: 20 }}>
				<Box className="container" px={{ base: "4%", xl: 0 }}>
					<Box textAlign="center" mb="12">
						<Heading
							as="h6"
							fontSize="sm"
							fontWeight="900"
							color="orange.100"
						>
							TRUSTED PARTNERS
						</Heading>
						<Text
							color="gray.200"
							size="sm"
							lineHeight="1.8rem"
							width={{ md: "60%", lg: "50%" }}
							margin="0 auto"
							my="4"
						>
							KTPARCELS HAS LEVERAGED ON HER GLOBAL PARTNERSHIP TO
							MAINTAIN A GLOBAL REACH TO MEET OUR CUSTOMERS’ NEEDS
							IN EVERY FACETS OF PROVIDING LOGISTICS SERVICE
						</Text>
					</Box>
					<Flex
						gap={{ base: 3, md: 10 }}
						overflowX="scroll"
						justifyContent={{ xl: "center" }}
						color="gray.200"
						sx={{
							"&::-webkit-scrollbar": {
								width: " 0px",
							},
							"&::-webkit-scrollbar-track": {
								background: "none",
							},
							"&::-webkit-scrollbar-thumb": {
								background: "none",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								background: "none",
							},
							"& svg": {
								width: "30px",
								height: "30px",
								verticalAlign: "middle",
							},
						}}
					>
						<Flex as="span" whiteSpace="nowrap">
							<XcapitalIcon />
							<Heading fontWeight={900} ml="1" size="md">
								XCAPITAL
							</Heading>
						</Flex>
						<Flex as="span" whiteSpace="nowrap">
							<ProxibidIcon />
							<Heading fontWeight={900} ml="1" size="md">
								PROXIBID
							</Heading>
						</Flex>
						<Flex as="span" whiteSpace="nowrap">
							<CactusIcon />
							<Heading fontWeight={900} ml="1" size="md">
								CACTUS
							</Heading>
						</Flex>
						<Flex as="span" whiteSpace="nowrap">
							<BoatsIcon />
							<Heading fontWeight={900} ml="1" size="md">
								BOATS.COM
							</Heading>
						</Flex>
						<Flex as="span" whiteSpace="nowrap">
							<ThamesIcon />
							<Heading fontWeight={900} ml="1" size="md">
								THAMES WATER
							</Heading>
						</Flex>
						<Flex as="span" whiteSpace="nowrap">
							<MarionIcon />
							<Heading fontWeight={900} ml="1" size="md">
								MARION BROTHERS
							</Heading>
						</Flex>
					</Flex>
				</Box>
			</Box>
		</MainLayout>
	);
};
