import { Box } from "@chakra-ui/react";
import React from "react";

export const Button = ({ children, variant = "filled", ...rest }) => {
	return (
		<Box
			as="button"
			lineHeight="1.2"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			border="1px"
			px="20px"
			py="12px"
			borderRadius="none"
			fontSize="smaller"
			fontWeight="700"
			bg="#f5f6f7"
			borderColor={variant === "outline" ? "white" : "orange.100"}
			color={variant === "outline" ? "orange.100" : "white"}
			background={variant === "outline" ? "white" : "orange.100"}
			minWidth={"120px"}
			wordBreak="normal"
			_hover={{
				background: variant === "outline" ? "orange.100" : "white",
				color: variant === "outline" ? "white" : "orange.100",
				borderColor: variant === "outline" ? "white" : "orange.100",
			}}
			_active={{
				bg: "#dddfe2",
				transform: "scale(0.98)",
				borderColor: "#bec3c9",
			}}
			_disabled={{
				bg: "gray.200",
				borderColor: "gray.200",
				color: "white",
			}}
			{...rest}
		>
			{children}
		</Box>
	);
};
