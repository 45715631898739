import { Box, Flex, Link, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { NavLink as ReactLink } from "react-router-dom";
import { CloseIcon, HamburgerIcon, LogoIcon } from "../../assets";

export const Header = () => {
	const [showMobileNav, setShowMobileNav] = useState(false);
	return (
		<>
			{/* Big header */}
			<Box
				as="header"
				py="4"
				display={{ base: "none", md: "block" }}
				boxShadow="sm"
			>
				<Box className="container">
					<Flex alignItems="center" justifyContent="space-between">
						<Link as={ReactLink} to="/">
							<Flex
								alignItems="center"
								sx={{
									"& svg": {
										width: "50px",
										height: "50px",
									},
								}}
							>
								<LogoIcon />
								<Text
									as="span"
									color="blue.100"
									fontSize="lg"
									fontWeight="900"
									ml="2"
								>
									KTparcels
								</Text>
							</Flex>
						</Link>
						<Stack direction="row" spacing={10} as="nav">
							<Link
								color="blue.100"
								fontSize="md"
								fontWeight="700"
								as={ReactLink}
								to="/"
							>
								Home
							</Link>
							<Link
								color="blue.100"
								fontSize="md"
								fontWeight="700"
								as={ReactLink}
								to="/tracking"
							>
								Track
							</Link>
							<Link
								color="blue.100"
								fontSize="md"
								fontWeight="700"
								as={ReactLink}
								to="/about"
							>
								About
							</Link>
							<Link
								color="blue.100"
								fontSize="md"
								fontWeight="700"
								as={ReactLink}
								to="/contact"
							>
								Contact
							</Link>
						</Stack>
					</Flex>
				</Box>
			</Box>
			{/* Small header */}
			<Box as="header" py="4" display={{ md: "none" }} boxShadow="sm">
				<Box className="container">
					<Flex alignItems="center" justifyContent="space-between">
						<Link as={ReactLink} to="/">
							<Flex
								alignItems="center"
								sx={{
									"& svg": {
										width: "50px",
										height: "50px",
									},
								}}
							>
								<LogoIcon />
								<Text
									as="span"
									color="blue.100"
									fontSize="lg"
									fontWeight="900"
									ml="2"
								>
									KTparcels
								</Text>
							</Flex>
						</Link>
						<Box
							sx={{
								"& svg": {
									width: "25px",
									height: "25px",
								},
							}}
							cursor="pointer"
						>
							{!showMobileNav ? (
								<HamburgerIcon
									onClick={() => setShowMobileNav(true)}
								/>
							) : (
								<CloseIcon
									onClick={() => setShowMobileNav(false)}
								/>
							)}
						</Box>
					</Flex>
					<Box py="10" display={showMobileNav ? "block" : "none"}>
						<Stack spacing={10} as="nav">
							<Link
								color="blue.100"
								fontSize="md"
								fontWeight="700"
								textAlign="center"
								as={ReactLink}
								to="/"
							>
								Home
							</Link>
							<Link
								color="blue.100"
								fontSize="md"
								fontWeight="700"
								textAlign="center"
								as={ReactLink}
								to="/tracking"
							>
								Track
							</Link>
							<Link
								color="blue.100"
								fontSize="md"
								fontWeight="700"
								textAlign="center"
								as={ReactLink}
								to="/about"
							>
								About
							</Link>
							<Link
								color="blue.100"
								fontSize="md"
								fontWeight="700"
								textAlign="center"
								as={ReactLink}
								to="/contact"
							>
								Contact
							</Link>
						</Stack>
					</Box>
				</Box>
			</Box>
		</>
	);
};
