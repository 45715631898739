import { Box, Flex, Grid, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import {
	BoatsIcon,
	CactusIcon,
	davies,
	vivian,
	thomas,
	cargoShip,
	homebg,
	MarionIcon,
	ProxibidIcon,
	sectionThreeImage,
	ThamesIcon,
	XcapitalIcon,
} from "../../assets";
import { MainLayout } from "../../layouts";

export const About = () => {
	return (
		<MainLayout>
			<Box>
				<Box
					as="section"
					backgroundColor="orange.100"
					backgroundImage={homebg}
					backgroundRepeat="no-repeat"
					backgroundPosition={["right", "center"]}
					backgroundSize="cover"
					position="relative"
					color="white"
					py="70"
				>
					<Box
						position="absolute"
						inset="0"
						background="rgba(255,107,33,0.5)"
					></Box>
					<Box className="container" position="relative">
						<Heading>
							<Flex
								justifyContent="center"
								sx={{
									"& svg": {
										width: "40px",
										height: "40px",
										verticalAlign: "middle",
										fill: "white",
										marginRight: "6px",
									},
								}}
							>
								<MarionIcon />
								About
							</Flex>
						</Heading>
					</Box>
				</Box>
				<Box as="section" bg="white" pt={{ base: 10 }}>
					<Box>
						<Box className="container" px={{ base: "4%", xl: 0 }}>
							<Box textAlign="center" mb="12">
								<Heading
									as="h6"
									fontSize="sm"
									fontWeight="900"
									color="orange.100"
								>
									FOR ALL YOUR CARGO NEEDS
								</Heading>
								<Heading
									as="h4"
									fontSize={{ base: "3xl", md: "4xl" }}
									fontWeight="800"
									color="blue.100"
									lineHeight={{ base: "2.5rem", md: "3rem" }}
									my="4"
								>
									KTparcels - The Right Choice
								</Heading>
							</Box>
						</Box>
						<Box as="section" bg="orange.100" py={{ base: 20 }}>
							<Box
								className="container"
								px={{ base: "4%", xl: 0 }}
							>
								<Flex
									direction={{ base: "column", lg: "row" }}
									alignItems={{ md: "center" }}
									gap={{ base: 5, md: 7, lg: 10, xl: 20 }}
								>
									<Box>
										<Box
											width={{
												base: "100%",
												md: "380px",
												lg: "500px",
											}}
											height={{
												base: "300",
												md: "380px",
												lg: "500px",
											}}
											bg="orange.100"
										>
											<Image
												src={sectionThreeImage}
												objectFit="cover"
												height="100%"
												width="100%"
											/>
										</Box>
									</Box>
									<Box>
										<Box my="7">
											<Heading
												as="h6"
												fontSize="sm"
												fontWeight="900"
												color="white"
												mb="2"
											>
												WHY CHOOSE US
											</Heading>
											<Text
												color="blue.100"
												size="sm"
												lineHeight="1.8rem"
												mb="4"
												fontWeight={700}
											>
												KTparcels is highly experienced
												in its field, and is extremely
												flexible in its ability to adapt
												to customers’ requirements.
											</Text>
											<Text
												color="white"
												size="sm"
												lineHeight="1.8rem"
											>
												The company provides an
												extensive variety of services in
												air, sea and land shipping,
												projects, door to door
												transport, logistics, shipment
												packaging, handling and custom
												clearance of art object,
												distribution of bulk mail,
												personal items, land transport,
												import and export, handling
												imports, air, sea and custom
												clearance of food products,
												shipments of
												temperature-controlled drugs,
												passenger escort, time critical
												and more.
											</Text>
										</Box>
										<Box my="7">
											<Heading
												as="h6"
												fontSize="sm"
												fontWeight="900"
												color="white"
												mb="2"
											>
												THE MISSION
											</Heading>
											<Text
												color="blue.100"
												size="sm"
												lineHeight="1.8rem"
												mb="4"
												fontWeight={700}
											>
												Be a global player in the
												courier industry, with
												excellence in products and
												services.
											</Text>
											<Text
												color="white"
												size="sm"
												lineHeight="1.8rem"
											>
												KTparcels' ability is to
												identify and understand its
												clients needs, fulfill and
												maintain service levels and
												continuously adapt and develop
												systems to fulfill all
												requirements, To plan, develop
												and continuously improve our
												facilities and services. To be
												customer driven in everything we
												do. To provide an ideal working
												environment that boosts
												co-operation and teamwork.
											</Text>
										</Box>
										<Box my="7">
											<Heading
												as="h6"
												fontSize="sm"
												fontWeight="900"
												color="white"
												mb="2"
											>
												OUR STRATEGY
											</Heading>
											<Text
												color="blue.100"
												size="sm"
												lineHeight="1.8rem"
												mb="4"
												fontWeight={700}
											>
												KTparcels will work closely with
												you to devise customized
												solutions and route optimization
												that will help your business
												improve customer service and
												decrease operational costs
											</Text>
											<Text
												color="white"
												size="sm"
												lineHeight="1.8rem"
											>
												KTparcels works with clients to
												develop and execute
												communication processes that
												optimize the flow of products
												and information within the
												supply chain and within each
												distribution center.
											</Text>
										</Box>
									</Box>
								</Flex>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box as="section" bg="gray.50" py={{ base: 12, md: 20 }}>
					<Box className="container" px={{ base: "4%", xl: 0 }}>
						<Box textAlign="center" mb="12">
							<Heading
								as="h6"
								fontSize="sm"
								fontWeight="900"
								color="orange.100"
							>
								MEET OUR TEAM
							</Heading>
							<Heading
								as="h4"
								fontSize={{ base: "3xl", md: "4xl" }}
								fontWeight="800"
								color="blue.100"
								lineHeight={{ base: "2.5rem", md: "3rem" }}
								my="4"
							>
								Dedicated & Professionals
							</Heading>
						</Box>
						<Box>
							<Grid
								templateColumns={{ md: "repeat(3, 1fr)" }}
								gap={{ base: 10, md: 5 }}
							>
								<Box>
									<Box
										width={{
											base: "100%",
											md: "200px",
											lg: "300px",
										}}
										height={{
											base: "300",
											md: "200px",
											lg: "300px",
										}}
										bg="orange.100"
									>
										<Image
											src={davies}
											objectFit="cover"
											height="100%"
											width="100%"
										/>
									</Box>
									<Box>
										<Heading
											as="h5"
											fontSize={{ base: "sm", md: "md" }}
											fontWeight="800"
											color="blue.100"
											my="4"
										>
											Davies Hill
										</Heading>
										<Text
											color="gray.200"
											size="sm"
											lineHeight="1.8rem"
											width={{ md: "90%" }}
										>
											Davies Hill, Chief Operating
											Officer, joined KTparcels in
											September, 2013. He served in senior
											distribution and logistics operating
											roles in a variety of industries
											including food distribution,
											beverage supply chain, and oil & gas
											distribution. Davies's background
											includes the design, development and
											operation of several regional and
											national distribution networks.
										</Text>
									</Box>
								</Box>
								<Box>
									<Box
										width={{
											base: "100%",
											md: "200px",
											lg: "300px",
										}}
										height={{
											base: "300",
											md: "200px",
											lg: "300px",
										}}
										bg="orange.100"
									>
										<Image
											src={vivian}
											objectFit="cover"
											height="100%"
											width="100%"
										/>
									</Box>
									<Box>
										<Heading
											as="h5"
											fontSize={{ base: "sm", md: "md" }}
											fontWeight="800"
											color="blue.100"
											my="4"
										>
											Vivan Smith
										</Heading>
										<Text
											color="gray.200"
											size="sm"
											lineHeight="1.8rem"
											width={{ md: "90%" }}
										>
											Vivan has over ten years experience
											and is one of the most senior
											commentators on industry issues. Her
											professional experience has been
											gained within market leading and
											multi-national companies.
										</Text>
									</Box>
								</Box>
								<Box>
									<Box
										width={{
											base: "100%",
											md: "200px",
											lg: "300px",
										}}
										height={{
											base: "300",
											md: "200px",
											lg: "300px",
										}}
										bg="orange.100"
									>
										<Image
											src={thomas}
											objectFit="cover"
											height="100%"
											width="100%"
										/>
									</Box>
									<Box>
										<Heading
											as="h5"
											fontSize={{ base: "sm", md: "md" }}
											fontWeight="800"
											color="blue.100"
											my="4"
										>
											Thomas Jake
										</Heading>
										<Text
											color="gray.200"
											size="sm"
											lineHeight="1.8rem"
											width={{ md: "90%" }}
										>
											Thomas joined KTparcels in June,
											2013. Harry has a wealth of
											knowledge about the parcel delivery
											sector and over the last 10 years
											has held a number of senior
											management His focus will be to
											expand KTparcels service options to
											improve our clients’ supply chain,
											helping them meet the needs of their
											customers.
										</Text>
									</Box>
								</Box>
							</Grid>
						</Box>
					</Box>
				</Box>
				<Box
					as="section"
					backgroundColor="orange.100"
					backgroundImage={cargoShip}
					backgroundRepeat="no-repeat"
					backgroundPosition={["right", "center"]}
					backgroundSize="cover"
					position="relative"
					color="white"
					py="180"
				>
					<Box
						position="absolute"
						inset="0"
						background="rgba(255,107,33,0.5)"
					></Box>
					<Box className="container" position="relative">
						<Heading textAlign="center">
							WE ARE DEDICATED TO PROVIDE THE BEST SERVICE AT THE
							LOWEST POSSIBLE COST
						</Heading>
					</Box>
				</Box>
				<Box as="section" bg="white" py={{ base: 20 }}>
					<Box className="container" px={{ base: "4%", xl: 0 }}>
						<Box textAlign="center" mb="12">
							<Heading
								as="h6"
								fontSize="sm"
								fontWeight="900"
								color="orange.100"
							>
								TRUSTED PARTNERS
							</Heading>
							<Text
								color="gray.200"
								size="sm"
								lineHeight="1.8rem"
								width={{ md: "60%", lg: "50%" }}
								margin="0 auto"
								my="4"
							>
								KTPARCELS HAS LEVERAGED ON HER GLOBAL
								PARTNERSHIP TO MAINTAIN A GLOBAL REACH TO MEET
								OUR CUSTOMERS’ NEEDS IN EVERY FACETS OF
								PROVIDING LOGISTICS SERVICE
							</Text>
						</Box>
						<Flex
							gap={{ base: 3, md: 10 }}
							overflowX="scroll"
							justifyContent={{ xl: "center" }}
							color="gray.200"
							sx={{
								"&::-webkit-scrollbar": {
									width: " 0px",
								},
								"&::-webkit-scrollbar-track": {
									background: "none",
								},
								"&::-webkit-scrollbar-thumb": {
									background: "none",
								},
								"&::-webkit-scrollbar-thumb:hover": {
									background: "none",
								},
								"& svg": {
									width: "30px",
									height: "30px",
									verticalAlign: "middle",
								},
							}}
						>
							<Flex as="span" whiteSpace="nowrap">
								<XcapitalIcon />
								<Heading fontWeight={900} ml="1" size="md">
									XCAPITAL
								</Heading>
							</Flex>
							<Flex as="span" whiteSpace="nowrap">
								<ProxibidIcon />
								<Heading fontWeight={900} ml="1" size="md">
									PROXIBID
								</Heading>
							</Flex>
							<Flex as="span" whiteSpace="nowrap">
								<CactusIcon />
								<Heading fontWeight={900} ml="1" size="md">
									CACTUS
								</Heading>
							</Flex>
							<Flex as="span" whiteSpace="nowrap">
								<BoatsIcon />
								<Heading fontWeight={900} ml="1" size="md">
									BOATS.COM
								</Heading>
							</Flex>
							<Flex as="span" whiteSpace="nowrap">
								<ThamesIcon />
								<Heading fontWeight={900} ml="1" size="md">
									THAMES WATER
								</Heading>
							</Flex>
							<Flex as="span" whiteSpace="nowrap">
								<MarionIcon />
								<Heading fontWeight={900} ml="1" size="md">
									MARION BROTHERS
								</Heading>
							</Flex>
						</Flex>
					</Box>
				</Box>
			</Box>
		</MainLayout>
	);
};
