import {
	AspectRatio,
	Box,
	Flex,
	Grid,
	Heading,
	List,
	ListItem,
	Text,
	Textarea,
} from "@chakra-ui/react";
import React from "react";
import { AvatarIcon, homebg } from "../../assets";
import { AppInput, Button } from "../../components";
import { MainLayout } from "../../layouts";

export const Contact = () => {
	return (
		<MainLayout>
			<Box>
				<Box
					as="section"
					backgroundColor="orange.100"
					backgroundImage={homebg}
					backgroundRepeat="no-repeat"
					backgroundPosition={["right", "center"]}
					backgroundSize="cover"
					position="relative"
					color="white"
					py="70"
				>
					<Box
						position="absolute"
						inset="0"
						background="rgba(255,107,33,0.5)"
					></Box>
					<Box className="container" position="relative">
						<Heading>
							<Flex
								justifyContent="center"
								sx={{
									"& svg": {
										width: "40px",
										height: "40px",
										verticalAlign: "middle",
										fill: "white",
										marginRight: "10px",
									},
								}}
							>
								<AvatarIcon />
								Contact
							</Flex>
						</Heading>
					</Box>
				</Box>
				<AspectRatio ratio={16 / 9} maxH={500} bg="orange.100">
					<iframe
						title="map"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2761.7543161754124!2d6.159058727351548!3d46.195447493365975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c7aca9f56f903%3A0xa08358c1d02ee078!2sAv.%20Krieg%2C%201208%20Gen%C3%A8ve%2C%20Switzerland!5e0!3m2!1sen!2sng!4v1672747707465!5m2!1sen!2sng"
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
					></iframe>
				</AspectRatio>
				<Box py={["10", "50"]}>
					<Box className="container">
						<Grid
							gridTemplateColumns={{ md: "repeat(4, 1fr)" }}
							direction={{ base: "column", md: "row" }}
							fontSize="14px"
							gap={{ base: "20px", md: "40px" }}
						>
							<Box>
								<Text mb="5" color="blue.100" fontWeight="900">
									VISIT US
								</Text>
								<List
									sx={{
										"& li": {
											marginBottom: "10px",
										},
									}}
								></List>
							</Box>
							<Box>
								<Text mb="5" color="blue.100" fontWeight="900">
									Location
								</Text>
								<List
									sx={{
										"& li": {
											marginBottom: "10px",
										},
									}}
								>
									<ListItem>
										Av. Krieg, 1208 Genève, Switzerland
									</ListItem>
								</List>
							</Box>
							<Box>
								<Text mb="5" color="blue.100" fontWeight="900">
									Opening Hours
								</Text>
								<List
									sx={{
										"& li": {
											marginBottom: "10px",
										},
									}}
								>
									<ListItem>Mon - Fri: 7am - 10pm</ListItem>
									<ListItem>Saturday: 8am - 10pm</ListItem>
									<ListItem>Sunday: 8am - 11pm</ListItem>
								</List>
							</Box>
							<Box>
								<Text mb="5" color="blue.100" fontWeight="900">
									Customer Service
								</Text>
								<List
									sx={{
										"& li": {
											marginBottom: "10px",
										},
									}}
								>
									<ListItem>
										Email: ktparcels@gmail.com
									</ListItem>
									<ListItem>Tel: +4617847935</ListItem>
								</List>
							</Box>
						</Grid>
					</Box>
				</Box>
				<Box className="container" mb={{ base: 70 }}>
					<Heading
						as="h2"
						textAlign="center"
						fontSize={{ base: "xl", md: "2xl" }}
						mb="5"
					>
						WE WORK{" "}
						<Text as="span" color="orange.100">
							24HOURS
						</Text>{" "}
						OF THE DAY AND
						<Text as="span" color="orange.100">
							{" "}
							7DAYS{" "}
						</Text>{" "}
						OF THE WEEK.
					</Heading>
					<Box maxW={600} margin={{ md: "30px auto" }}>
						<Box as="form">
							<Flex
								direction={{ base: "column" }}
								gap={{ base: 4 }}
							>
								<AppInput placeholder="Name" />
								<AppInput placeholder="Email" />
								<AppInput placeholder="Phone" />
								<Textarea
									fontSize="sm"
									borderRadius="none"
									borderColor="gray.100"
									placeholder="Type your message here..."
								/>
								<Button>Submit</Button>
							</Flex>
						</Box>
					</Box>
				</Box>
			</Box>
		</MainLayout>
	);
};
